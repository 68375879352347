import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import config from '../utils/siteConfig'
import Layout from '../components/Layout/Layout'
import Container from '../components/Container'
import SEO from '../components/SEO'
import './base.scss'
import BigContentCard from '../components/BigContentCard/BigContentCard'
import SolutionPageHero from '../components/SolutionPage/SolutionPageHero/SolutionPageHero'
import { handleCustomContent, handleEmbeds } from '../utils/contentMapping'
import CallToActionBanner from '../components/CallToActionBanner/CallToActionBanner'
import FreeConsultingForm from '../components/FreeConsultingForm/FreeConsultingForm'
import TrainingForm from '../components/TrainingForm/TrainingForm'

const SolutionPageTemplate = ({ data }) => {
  const {
    title,
    slug,
    body,
    hero,
    suggestedContent,
    formType,
  } = data.contentfulSolution

  const postNode = data.contentfulSolution

  const renderContent = () => {
    let generated = handleCustomContent(
      body.childContentfulRichText.html,
      postNode
    )
    if (generated && generated.length && Array.isArray(generated)) {
      return generated.map(c => {
        if (typeof c === 'string') {
          return <div dangerouslySetInnerHTML={{ __html: c }} />
        } else {
          return handleEmbeds(c, postNode)
        }
      })
    } else if (typeof generated === 'string') {
      return <div dangerouslySetInnerHTML={{ __html: generated }} />
    }
  }

  const renderForm = type => {
    if (type === 'freeConsulting') {
      return <FreeConsultingForm />
    } else if (type === 'training') {
      return <TrainingForm />
    } else {
      return <CallToActionBanner type={'signup'} />
    }
  }

  return (
    <Layout>
      <Helmet>
        <title>{`${title} - ${config.siteTitle}`}</title>
      </Helmet>
      <SEO pagePath={slug} postNode={postNode} pageSEO />
      <SolutionPageHero hero={hero} />
      <Container
        type={
          'basic-typography big-padding solution-page solution-page-content'
        }
      >
        {renderContent()}
      </Container>
      <Container type={'big-padding solution-page padding-top-none'}>
        {suggestedContent ? (
          <BigContentCard content={suggestedContent} />
        ) : null}{' '}
      </Container>
      <a name="form" id="form" />
      <div className="solution-page-form"> {renderForm(formType)}</div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulSolution(slug: { eq: $slug }) {
      title
      slug
      formType
      hero {
        title
        subTitle
        callToAction
        callToActionLink
        background {
          title
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
      metaDescription {
        internal {
          content
        }
      }
      body {
        childContentfulRichText {
          html
        }
      }
      suggestedContent {
        type
        heroImage {
          title
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        shortDescription
        title
        slug
      }
    }
  }
`

export default SolutionPageTemplate

import React from 'react'
import './FreeConsultingForm.scss'
import { FaCheckCircle, FaFlag, FaCloud, FaPaperPlane } from 'react-icons/fa'
const axios = require('axios')

export class FreeConsultingForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hideForm: false,
    }
  }

  showThankYou() {
    this.setState({ hideForm: true })
  }

  onchangeText(val) {
    var OriginalValue = document.getElementById('name').value
    document.getElementById('first_name').value = OriginalValue.substr(
      0,
      OriginalValue.indexOf(' ')
    )
    document.getElementById('last_name').value = OriginalValue.substr(
      OriginalValue.indexOf(' ') + 1
    )
  }

  submitForm(ev) {}

  renderFormSuccess() {
    let el
    if (this.state.hideForm) {
      el = (
        <div className="thanks-wrap">
          <div className="thanks-text">
            <div>
              Thanks for the message! We'll be in touch within the next day or
              so.
            </div>
            <div className="thanks-icon">
              <FaCheckCircle />
            </div>
          </div>
        </div>
      )
    } else {
    }
    return el
  }

  renderForm() {
    let el
    el = (
      <div>
        <form
          onSubmit={this.submitForm.bind(this)}
          action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
          method="POST"
          id="TRAINING FORM"
          noValidate
        >
          <input type="hidden" name="oid" value="00D4T000000FZaP" />
          <input type="hidden" name="retURL" value="http://rackner.com?thankyou=freeconsulting" />
          <input
            type="hidden"
            id="first_name"
            maxLength="40"
            name="first_name"
            size="20"
          />
          <input
            type="hidden"
            id="last_name"
            maxLength="80"
            name="last_name"
            size="20"
          />
          <div className="_form-content">
            <div className="_form_element _x70061606 _full_width ">
              <div className="_field-wrapper">
                <input
                  type="text"
                  onChange={this.onchangeText}
                  name="name"
                  id="name"
                  placeholder="Full Name"
                />
              </div>
            </div>
            <div className="_form_element _x28370249 _full_width ">
              <div className="_field-wrapper">
                <input
                  type="text"
                  name="email"
                  placeholder="Email Address"
                  required
                />
              </div>
            </div>
            <div className="_form_element _field1 _full_width ">
              <div className="_field-wrapper">
                <input type="text" name="company" placeholder="Company" />
              </div>
            </div>
            <div className="_form_element _field1 _full_width ">
              <div className="_field-wrapper">
                <textarea
                  type="text"
                  name="description"
                  placeholder="Description"
                />
              </div>
            </div>
            <div className="_button-wrapper _full_width">
              <button id="_form_1_submit" className="form-button" type="submit">
                Submit
              </button>
            </div>
            <div className="_clear-element" />
          </div>
          <div
            className="_form-thank-you"
            style={{
              display: 'none',
            }}
          />
        </form>
      </div>
    )
    return el
  }

  render() {
    return (
      <div id="signup" className="free-consulting-form-upper-row padding-large">
        <div className="free-consulting-form-row">
          <div className="free-consulting-form-wrap">
            {this.state.hideForm ? this.renderFormSuccess() : this.renderForm()}
          </div>
          <div className="free-consulting-form-text">
            <h2>Want a free hour of consulting with one of our experts?</h2>
            <h3>Fill out the form and we'll be in touch to set it up!</h3>
          </div>
        </div>
      </div>
    )
  }
}

export default FreeConsultingForm

import React from 'react'
import { Link } from 'gatsby'
import './SolutionPageHero.scss'

export class SolutionPageHero extends React.Component {
  render() {
    const { hero } = this.props
    return (
      <div
        className="solution-page-hero"
        style={{ backgroundImage: 'url(' + hero.background.fluid.src + ')' }}
      >
        <div className="overlay" />
        <div className="inner-wrap">
          <div className="text">
            <h1>{hero.title}</h1>
            <h2>{hero.subTitle}</h2>
            <Link to={hero.callToActionLink}>
              <button className="btn-raised-light-blue-inverse shadow">
                {hero.callToAction}
              </button>
            </Link>
          </div>
        </div>
      </div>
    )
  }
}

export default SolutionPageHero
